<template>
  <div
    class="px-4 mt-3 d-flex flex-column align-items-center"
    style="gap: 1rem"
  >
    <div class="bg-dark rounded-circle p-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        width="24"
        height="24"
        stroke-width="2"
      >
        <path
          d="M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3"
        ></path>
        <path d="M4 6v6c0 1.657 3.582 3 8 3m8 -3.5v-5.5"></path>
        <path d="M4 12v6c0 1.657 3.582 3 8 3"></path>
        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
        <path d="M20.2 20.2l1.8 1.8"></path>
      </svg>
    </div>
    <h4 class="text-white-50">No se encontraron resultados</h4>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
